// use $j instead of $ function 
var $j = jQuery.noConflict();

var Helper = {

	// email regex
	regexEmail: function (who) {
		var regexobj = /^[A-Za-z0-9]+([_\.-][A-Za-z0-9]+)*@[A-Za-z0-9]+([_\.-][A-Za-z0-9]+)*\.([A-Za-z]){2,9}$/i;
		return(regexobj.test(who));
	},

	// date regex
	regexDate: function (who) {
		var regexobj = /^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/i;
		return(regexobj.test(who));
	},

	// check platform
	isTabletOrMobile: function () {
		if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
						|| navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i)) {
			return true;
		}

		return false;
	},

	// check mobile device with small screen
	isSmallScreen: function () {
		$elem = $j('<div></div>').addClass('isSmallScreen');
		$j('body').append($elem);
		var result = $elem.is(':visible');
		$elem.remove();
		return result;
	},

	// check mobile button
	isMobileMenu: function () {
		var returnMenu = $j('.menu-toggle').is(':visible');
		return returnMenu;
	},

	mobileLang: function() {
		var mLang = '<li><a href="http://www.substitucna-liecba.sk" title="Substitučná-liečba.sk" class="langflag"><img src="/img/flag-slovak.png" alt="Substitučná-liečba.sk"></a></li>'
		$("#mm-1").find( ".mm-listview" ).append(mLang);
	},

	resetStars: function () {
		for (i = 1; i < 6; i++) { 
			$j('.star'+i).removeClass('hovered');	
		}
	}
};

var Frontend = {

	initLoad: function () {
		$j(window).load(function () {
			$j('body').addClass('loaded');

			var heightScreen = (window.outerHeight)-30;
			$j('.main-content').css('min-height',heightScreen);
		});
		$j('a').mouseup(function(e){
			var isCtrlPressed = e.ctrlKey;
			var isShiftPressed = e.shiftKey;
			var isCommandPressed = e.metaKey;
			if (isCtrlPressed === true ||
					isShiftPressed === true ||
					isCommandPressed === true ||
					e.which == 2) {
				e.preventDefault();
				return false;
			}

			$j('a').not('[href^=mailto], [href^=tel], [href^=javascript], [href^="#"], [target^=_blank], .fancybox').click(function () {
				$j('body').removeClass('loaded');
			});
		});

	},

	initMmenu: function () {
		if (!Helper.isMobileMenu()){
			return false;
		} else {
			if (!$j('#mm-main-nav').length) {
				$j("#main-nav").mmenu({
					"extensions": [
						"pagedim-black"
					],
					"offCanvas": {
						"position": "right"
					}
				}, {
					clone: true,
				});
				Helper.mobileLang();
			}
		}
	},

	initRatings: function () {
		$j('.star1').hover(function(){
			$j(this).addClass('hovered');
		}, function(){
			Helper.resetStars();
		});
		$j('.star2').hover(function(){
			$j(this).addClass('hovered');
			$j('.star1').addClass('hovered');
		}, function(){
			Helper.resetStars();
		});
		$j('.star3').hover(function(){
			$j(this).addClass('hovered');
			$j('.star1').addClass('hovered');
			$j('.star2').addClass('hovered');
		}, function(){
			Helper.resetStars();
		});
		$j('.star4').hover(function(){
			$j(this).addClass('hovered');
			$j('.star1').addClass('hovered');
			$j('.star2').addClass('hovered');
			$j('.star3').addClass('hovered');
		}, function(){
			Helper.resetStars();
		});
		$j('.star5').hover(function(){
			$j(this).addClass('hovered');
			$j('.star1').addClass('hovered');
			$j('.star2').addClass('hovered');
			$j('.star3').addClass('hovered');
			$j('.star4').addClass('hovered');
		}, function(){
			Helper.resetStars();
		});
	},

	initNewsList: function () {
		$j(window).load(function () {
			if (!Helper.isSmallScreen()){
				return false;
			} else {
				var heightX = $j('#new-1').height();
				var heightDrugNewsList = $j('.drug-newslist #new-1').height();
				var heightDrugNewsListMobile = ((heightDrugNewsList*6)/2)+37;
				$j('.drug-newslist').css('height',heightDrugNewsListMobile).addClass('closed');
				$j('.show-btn-news .button').click(function () {
					$j(this).parent().parent().css('height','auto').removeClass('closed');
				});
			}
		});
	},

	initAskList: function () {
		$j(window).load(function () {
			if (!Helper.isSmallScreen()){
				return false;
			} else {
				var heightAskNewsList = $j('.ask-newslist').height();
				var heightAskNewsListMobile = (heightAskNewsList/2)+52;
				$j('.ask-newslist').css('height',heightAskNewsListMobile).addClass('closed');
				$j('.show-btn-ask .button').click(function () {
					$j(this).parent().parent().css('height','auto').removeClass('closed');
				});
			}
		});
	}

};